import React from "react";
import HeroImage from "../assets/images/hero-bg.jpg";
import ItechLogo from "../assets/images/transparent-logo.png";
import { RiContactsLine } from "react-icons/ri";
import { AiFillInstagram } from "react-icons/ai";
import { FaLinkedin } from "react-icons/fa";
import { AiFillTikTok } from "react-icons/ai";
import ResponsiveNavbar from "../components/ResponsiveNavbar";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { FiPhone } from "react-icons/fi";
import {Link} from "react-router-dom"

const ContactUs = () => {
  return (
    <div className="overflow-hidden h-max">
      <div className=" font-poppins bg-no-repeat font-light inset-0 bg-cover flex items-center flex-row max-md:flex-col bg-center max-md:bg-blocks ">
        <div
          className="custom-bg bg-center w-[55%] max-md:w-screen max-md:h-fit h-screen rounded-br-2xl max-md:rounded-none"
        
        >
          <div className="">
            <Link to="/">
            <img
              src={ItechLogo}
              alt="Itechlogo"
              className=" w-[400px] max-sm:w-[300px] max-md:m-auto cursor-pointer"
            />
            </Link>
            <div className="max-md:hidden">
              <h1 className="font-normal max-lg:text-4xl w-[90%] uppercase text-5xl leading-[58px] pl-20 font-poppins text-theme ">
                Ready to take the next step? Let's connect!
              </h1>
              <p className="font-light w-[70%] mt-8  pl-20 text-2xl max-lg:text-lg   font-poppins text-white">
                Whether you have a project in mind or simply want to learn more
                about our services, we're here to help. Get in touch with us
                today using the contact details provided below.
              </p>
            </div>
          </div>
        </div>
        <div className="w-[40%] relative max-md:w-screen max-md:mr-0  items center mr-[8%] h-[70%]">
          <ResponsiveNavbar color={"bg-black"} />
          <div className="flex flex-row justify-end max-md:justify-center items-center mb-4 max-md:w-screen text-gray-700 max-md:text-white border border-white max-md:bg-black max-md:mb-0 max-md:h-16">
            <a
              href="tel: +1 (437) 2620900"
              className="max-md:w-[50%] h-full max-md:px-[22px]"
            >
              {" "}
              <FaPhoneAlt className="border-l  border-r  border-black  m-auto text-base w-12 max-md:h-full max-md:p-[10px]" />
            </a>
            <a
              href="mailto: services@itechgram.com"
              className="max-md:w-[50%] max-md:px-[22px] h-full border-l max-md:border-white "
            >
              <MdEmail className=" border-black text-lg m-auto max-md:h-full max-md:p-[10px]  w-12" />{" "}
            </a>
          </div>
          <div className="cat-bg block max-md:w-screen h-[400px] max-w-screen bg-cover bg-center max-md:rounded-none bg-blocks rounded-3xl ml-[-10%] max-md:ml-0"></div>
        </div>
      </div>
      <div className="max-md:block hidden text-center h-max  bg-black w-screen p-10">
        <div className="w-11/12 m-auto">
          <h1 className="font-normal max-lg:text-4xl  uppercase text-5xl leading-[58px]  font-poppins text-theme ">
            Ready to take the next step? Let's connect!
          </h1>
          <p className="font-light  mt-8   text-2xl max-lg:text-lg   font-poppins text-white">
            Whether you have a project in mind or simply want to learn more
            about our services, we're here to help. Get in touch with us today
            using the contact details provided below.
          </p>
        </div>
      </div>
      <div className="container mt-20 m-auto">
        <div className="w-11/12 m-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mt-6">
          {/* Item 1 */}
          <div className="item  bg-white text-center p-7 shadow-lg rounded-lg border-5 cursor-ponter border-opacity-25 border-black transition-all hover:shadow-xl ">
            <div className="icon item text-6xl mb-6 text-white  rounded-full bg-theme m-auto w-24 h-24 leading-none flex items-center justify-center">
              <CiLocationOn />
            </div>
            <h6 className="mb-3 text-xl font-semibold  uppercase ">Address</h6>
            <p className="text-base leading-6">Mississauga, Ontario, Canada</p>
          </div>

          {/* Item 1 */}
          <div className="item  bg-white text-center p-7 shadow-lg rounded-lg border-5 cursor-ponter border-opacity-25 border-black transition-all hover:shadow-xl ">
            <div className="icon item text-6xl mb-6 text-white  rounded-full bg-theme m-auto w-24 h-24 leading-none flex items-center justify-center">
              <FiPhone />
            </div>
            <h6 className="mb-3 text-xl font-semibold   uppercase ">Phone</h6>
            <p className="text-base leading-6">+1 (437) 2620900</p>
          </div>

          <div className="item  bg-white text-center p-7 shadow-lg rounded-lg border-5 cursor-ponter border-opacity-25 border-black transition-all hover:shadow-xl ">
            <div className="icon item text-6xl mb-6 text-white  rounded-full bg-theme m-auto w-24 h-24 leading-none flex items-center justify-center">
              <MdOutlineEmail />
            </div>
            <h6 className="mb-3 text-xl font-semibold  uppercase ">EMAIL</h6>
            <p className="text-base leading-6">services@itechgram.com</p>
          </div>

          <div className="item  bg-white text-center p-7 shadow-lg rounded-lg border-5 cursor-ponter border-opacity-25 border-black transition-all hover:shadow-xl ">
            <div className="icon item text-6xl mb-6 text-white  rounded-full bg-theme m-auto w-24 h-24 leading-none flex items-center justify-center">
              <RiContactsLine />
            </div>
            <h6 className="mb-3 text-xl font-semibold  h uppercase ">
              social media platforms
            </h6>
            <div className="flex justify-center items-center  gap-2">
              <a
                target="_blank"
                href="https://www.tiktok.com/@itechgram?_t=8meQIyxuNqX&_r=1"
              >
                <AiFillTikTok className="text-theme text-4xl" />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/in/itechgram-solutions-5ab94530b/"
              >
                <FaLinkedin className="text-theme text-4xl" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/itechgramsolutions"
              >
                {" "}
                <AiFillInstagram className="text-theme text-4xl" />{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container  m-auto w-11/12 text-white text-center text-lg font-semibold p-10 bg-[#48bc95] my-16 ">
        <ul>
          <li className="mb-4">Get in Touch with itechgram Solutions</li>
          <li className="mb-4">
            We are here to assist you with any inquiries or questions. Whether
            you're looking for marketing solutions or want to discuss a
            potential project, we are eager to hear from you.
          </li>
          <i className="mb-4">
            Please feel free to contact us using the contact details provided
            above or by filling out the form below. We value your feedback and
            strive to provide exceptional service to our clients
          </i>
        </ul>
      </div>
    </div>
  );
};

export default ContactUs;
