import React from "react";
import Heading from "./Heading";
import Description from "./Description";
import ItechLogo from "../../assets/images/transparent-logo.png";
import {Link} from "react-router-dom"
import Button from "./Button";

const Banner = ({ image, bannerHeading }) => {
  return (
    <div className=" !h-[500px] ">
      <div
        className="h-full relative bg-cover bg-center z-20  flex justify-center items-center flex-col  pt-24 "
        style={{
          backgroundImage: ` url(${image})`,
        }}
      >
        <Link to="/">
        <img
          className="h-[100px] absolute top-[-6px] left-[-21px]"
          src={ItechLogo}
          alt="itechgram logo"
        ></img>
        </Link>
        <h1 class="text-5xl font-poppins text-center text-white font-bold">Services</h1>
       <div className="flex flex-col justify-center items-center mt-20">
        <h1 id="section1"  className="text-center font-bold  font-poppins uppercase   text-5xl text-theme">
            Business Consulting
          </h1>
          <p className="w-[75%]  text-center max-md:w-[90%] max-sm:w-[90%]    text-lg mt-6 text-white">
            Unlock the full potential of your business with our comprehensive
            business consulting services. Our expert team specializes in a range
            of services, including:
          </p>
     
          </div>
      </div>
    </div>
  );
};

export default Banner;
