import React, { Fragment } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Star, StarHalf } from "lucide-react";


const testimonialList = [
    {
      author: {
        fullName: "Aisha Khalid",
        picture: "https://cdn.easyfrontend.com/pictures/users/user22.jpg",
      },
      rating: 5,
      description:
        "I am beyond impressed with the services provided by itechgram. Their expertise and attention to detail have significantly boosted our online presence.",
    },
    {
      author: {
        fullName: "David Wong",
        picture: "https://cdn.easyfrontend.com/pictures/users/user4.jpg",
      },
      rating: 4,
      description:
        "itechgram transformed our digital strategy and delivered outstanding results. Our website traffic and customer engagement have never been better.",
    },
    {
      author: {
        fullName: "Fatima Saeed",
        picture: "https://cdn.easyfrontend.com/pictures/users/user20.jpg",
      },
      rating: 5,
      description:
        "The team at itechgram is incredibly professional and skilled. They understood our needs perfectly and exceeded our expectations.",
    },
    {
      author: {
        fullName: "Emily Chen",
        picture: "https://cdn.easyfrontend.com/pictures/users/user21.jpg",
      },
      rating: 4.5,
      description:
        "Working with itechgram has been a game-changer for our business. Their innovative solutions and dedicated support are unmatched.",
    },
    {
      author: {
        fullName: "Rami Al-Mansouri",
        picture: "https://cdn.easyfrontend.com/pictures/users/user18.jpg",
      },
      rating: 5,
      description:
        "We highly recommend itechgram for any business looking to enhance its digital footprint. Their comprehensive services have driven remarkable growth for us.",
    },
    {
      author: {
        fullName: "Rachel Smith",
        picture: "https://cdn.easyfrontend.com/pictures/users/user18.jpg",
      },
      rating: 3.8,
      description:
        "Thanks to itechgram, our online sales have increased significantly. Their team is knowledgeable, responsive, and always ready to help.",
    },
    {
      author: {
        fullName: "Kevin Li",
        picture: "https://cdn.easyfrontend.com/pictures/users/user18.jpg",
      },
      rating: 4.4,
      description:
        "itechgram's business consulting services have been invaluable to our company's growth. Their team provided expert guidance and practical strategies that have had a direct impact on our success",
    }
    
    
  ];

const Rating = ({ rating, showLabel, className }) => (
    <p className={className}>
        {[...Array(5)].map((_, i) => {
            const index = i + 1;
            let content = "";
            if (index <= Math.floor(rating))
                content = <Star key={i} className="text-yellow-500" />;
            else if (rating > i && rating < index + 1)
                content = <StarHalf key={i} className="text-yellow-500" />;
            else if (index > rating)
                content = <Star key={i} className="text-yellow-200 dark:text-opacity-20" />;

            return <Fragment key={i}>{content}</Fragment>;
        })}
        {showLabel && <span>{rating.toFixed(1)}</span>}
    </p>
);

const TestimonialItem = ({ testimonial }) => (
    <div className="bg-white border mr-2 rounded-2xl p-6 lg:p-8 h-[280px] md:h-[250px] cursor-pointer">

        <h4 className="text-xl font-medium mb-1">{testimonial.author.fullName}</h4>
        <Rating rating={testimonial.rating} showLabel={false} className="mb-6 flex flex-row" />
        <p className="opacity-80">{testimonial.description}</p>
    </div>
);

const ClientsReview = () => {
    const sliderSettings1 = {
        // dots: true,
        infinite: true,
        speed: 5000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1,
        cssEase: 'linear',
        pauseOnHover: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };

    return (
        <section className="ezy__testimonial7 light py-12 px-8 bg-[#f2f5f7]">
            <div className="container  mx-auto">
                <div className="grid grid-cols-12 mb-6 md:mb-12">
                    <div className="col-span-12 lg:col-span-5">
                        <h1 className='text-[22px] md:text-[1.75rem] lg:text-4xl font-bold pb-2 mb-4 md:mb-8' >
                            Everyone should believe in What Our Client Say.
                        </h1>
                    </div>
                </div>
                <Slider {...sliderSettings1} className="flex justify-center mb-8">
                    {testimonialList.map((testimonial, i) => (
                        <div key={i}>
                            <TestimonialItem testimonial={testimonial} />
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

export default ClientsReview;
