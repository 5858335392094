import React, { useEffect } from "react";
import ClientsReview from "../components/ClientsReview";
import dm from "../assets/images/dm.png";
import businessimage from "../assets/images/businessconsultingimage.png";
import seo from "../assets/images/seo.png";
import gd from "../assets/images/gd.png";
import wd from "../assets/images/wd.png";
import Banner from "../components/shared/Banner"
import mobileapp from "../assets/images/mobileapp.png";
import { useLocation } from "react-router-dom";
import itbg from "../assets/images/finalHero.jpg";
import ServiceItem from "../components/ServiceItem";
import { BusinessConsult } from "../components/BusinessConsult";
import ResponsiveNavbar from "../components/ResponsiveNavbar";
const Services = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const data = [
  
    {
      title: <p>Web <span className="text-theme"> Development</span> </p>,
      text: "Elevate your online presence with our professional web development services. Our experienced team combines creativity with technical expertise to design and develop responsive, user-friendly websites that captivate your audience and drive engagement.",
      image: wd,
    },
    {
      title: <p>Digital <span className="text-theme">Marketing</span></p>,
      text: "Reach your target audience effectively with our comprehensive digital marketing solutions. From social media management to email campaigns, we leverage data-driven strategies and cutting-edge techniques to enhance your online visibility, attract customers, and maximize ROI.",
      image: dm,
    },
    {
      title: <p>SEO <span className="text-theme">(search engine optimization)</span></p>,
      text: "Improve your search engine rankings and drive organic traffic to your website with our SEO services. Our proven strategies focus on keyword optimization, content creation, and technical enhancements to help you climb the search engine results pages and increase visibility.",
      image: seo,
    },
    {
      title: <p>Graphic <span className="text-theme">Design</span></p>,
     
      text: (
        <ul>
          <li>
            Unleash your brand's potential with itechgram's dynamic graphic
            design services. Our expert team specializes in:
          </li>
          <ul>
            <li>
              <span className="font-semibold">Logo Design: </span>Craft a unique
              and memorable logo that embodies your brand's identity.
            </li>
            <li>
              <span className="font-semibold">Branding Services: </span>
              Establish Your Company's Unique Identity
            </li>
            <li>
              <span className="font-semibold">Menu Designs: </span>Create
              visually appealing menus that showcase your offerings.
            </li>
            <li>
              <span className="font-semibold">Branding Collateral: </span>Design
              business cards, brochures, and more to reinforce your brand
              message.
            </li>
            {/* <li>Social Media Graphics: Engage your audience with eye-catching graphics tailored for social media platforms.</li> */}
          </ul>
        </ul>
      ),
      image: gd,
    },

    {
      title: <p>Mobile <span className="text-theme">Apps</span></p>,
      text: "Stay connected with your audience on the go with our custom mobile app development services. From iOS to Android, we design and develop intuitive, user-friendly mobile apps that engage users, drive conversions, and enhance brand loyalty.",
      image: mobileapp,
    },
  ];

  return (
    <div>
       <ResponsiveNavbar color={"bg-[#48bc95]"} />
      <Banner image={itbg} />
      <div className="h-max w-screen relative">
      <div className="bg-[#0A090C] h-[150px] w-screen ">
      </div>
   <div className="shadow-lg w-[60%] max-md:w-[84%] m-auto border border-gray-100 ">
        <img  className="mt-[-120px] w-full max-sm:mt-[-70px]  m-auto max-md:h-auto" src={businessimage} alt="business consulting"></img>
        <BusinessConsult />
        </div>
      
      </div>
     
      <div className="relative ">
      
      <div>
        
      </div>
      </div>
      <div id="section2">
      <div className="w-screen h-[400px] flex justify-center flex-col items-center bg-[#0A090C] ">
              
              <h1 className=" font-bold text-center text-theme max-sm:text-3xl max-md:text-4xl font-poppins  uppercase  text-5xl ">
                IT & MARKETING SOLUTIONS
              </h1>
              <p className="w-[75%] max-md:w-[90%] text-center max-sm:w-[90%] mx-auto  text-lg mt-6 text-white">
                Unlock the full potential of your online presence with our IT
                and marketing solutions. At itechgram, we offer a range of
                services designed to elevate your business in the digital realm.
                Our expert team specializes in:
              </p>
            </div>
        
        {data.map((item, index) => (
          <ServiceItem key={index} item={item} index={index} />
        ))}
      </div>
      <ClientsReview />
    </div>
  );
};

export default Services;
