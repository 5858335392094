import React from 'react'
import { Transition } from "@headlessui/react";
import {useState} from 'react'
import {Link} from "react-router-dom"
const ResponsiveNavbar = ({color}) => {
    const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
    {" "}
    {!isOpen && (
      <button
        className="fixed top-5 right-8 bg-grey-100 z-50 flex flex-col items-center justify-center w-10 h-10 rounded cursor-pointer"
        onClick={() => setIsOpen(true)}
       
      >
        <div className="flex flex-col hover:gap-1">
          <span className={`block w-7 h-0.5  max-md:bg-white mb-1 ${color} `}></span>
          <span className={`block w-7 h-0.5 max-md:bg-white mb-1 ${color}`}></span>
          <span className={`block w-7 h-0.5 max-md:bg-white ${color}`}></span>
        </div>
      </button>
    )}

    <Transition
      show={isOpen}
      enter="transition ease-in-out duration-300 transform"
      enterFrom="-translate-x-full"
      enterTo="translate-x-0"
      leave="transition ease-in-out duration-300 transform"
      leaveFrom="translate-x-0"
      leaveTo="-translate-x-full"
    >
      <div className="fixed inset-0 bg-black bg-opacity-50 z-40 flex items-center justify-center">
        
          <button
            className="absolute text-white top-4 right-10 text-5xl font-thin"
            onClick={() => setIsOpen(false)}
          >
            &times;
          </button>
          <ul className=" w-fit m-auto flex flex-col justify-center h-full items-center text-center space-y-4 text-white font-bold uppercase">
          <Link to="/">  <li className="text-xl active:bg-white active:text-gray-700 mx-auto w-40 hover:border border-white p-2 cursor-pointer ">
              Home
            </li>
            </Link>
            <Link to="/services">
            <li className="text-xl active:bg-white active:text-gray-700 w-40 hover:border border-white cursor-pointer p-2">
              Services
            </li>
            </Link>
            <Link to="/contact-us">
            <li className="text-xl w-40 hover:border border-white active:bg-white active:text-gray-700 cursor-pointer p-2">
              Contact Us
            </li>
            </Link>
          </ul>
        
      </div>
    </Transition>
  </div>
  )
}

export default ResponsiveNavbar