import React from "react";


import box from "../assets/images/box.png";
export const BusinessConsult = () => {
  return (
    <div className="w-11/4 mr-2 m-auto relative py-5 pb-0">
      <div className="container max-sm:px-0 py-10  w-[90%] m-auto">
        <ul className="bullets">
          <li>
            <div className="flex flex-row max-sm:justify-center items-baseline max-sm:mb-2">
              <img
                src={box}
                className="custom-bullet w-4  mr-2  inline-block"
              ></img>{" "}
              <span className="font-bold max-sm:text-lg text-lg ">
                Strategic Planning
              </span>
            </div>
            <p className="ml-7 max-sm:text-center max-sm:ml-0 m  mt-[-6px] w-[90%] max-sm:w-[100%]">
              Develop actionable plans to achieve long-term business goals,
              aligning your vision with market opportunities and internal
              capabilities.
            </p>
          </li>

          <li>
            <div className="flex flex-row max-sm:justify-center items-baseline max-sm:mb-2">
              <img src={box} className="w-4 mr-2 inline-block"></img>{" "}
              <span className="font-bold max-sm:text-lg text-lg ">
                Market Research
              </span>
            </div>
            <p className="ml-7 max-sm:ml-0 max-sm:text-center mt-[-6px] w-[90%] max-sm:w-[100%]">
              Gain insights into market trends, customer needs, and competitive
              landscapes, enabling you to make informed decisions and stay
              ahead.
            </p>
          </li>

          <li>
            <div className="flex flex-row max-sm:justify-center items-baseline max-sm:mb-2">
              <img src={box} className="w-4 mr-2 inline-block"></img>{" "}
              <span className="font-bold max-sm:text-lg text-lg ">
                Financial Consulting
              </span>
            </div>
            <p className="ml-7 max-sm:ml-0 max-sm:text-center mt-[-6px] w-[90%] max-sm:w-[100%] ">
              Optimize your financial performance with expert advice on
              budgeting, forecasting, and financial strategy, ensuring
              sustainable growth and profitability.
            </p>
          </li>

          <li>
            <div className="flex flex-row max-sm:justify-center items-baseline max-sm:mb-2">
              <img src={box} className="w-4 mr-2 inline-block"></img>{" "}
              <span className="font-bold max-sm:text-base text-lg ">
                Business Process Optimization
              </span>
            </div>
            <p className="ml-7 max-sm:ml-0 max-sm:text-center mt-[-6px] w-[90%] max-sm:w-[100%] ">
              Streamline your operations with business process optimization,
              aimed at improving efficiency, reducing costs, and enhancing
              overall productivity.
            </p>
          </li>

          <li>
            <div className="flex flex-row max-sm:justify-center items-baseline max-sm:mb-2">
              <img src={box} className="w-4 mr-2 inline-block"></img>{" "}
              <span className=" font-bold max-sm:text-base text-lg ">
                Marketing & Business Strategy
              </span>
            </div>
            <p className="ml-7 max-sm:ml-0 max-sm:text-center mt-[-6px] w-[90%] max-sm:w-[100%] ">
              Craft powerful marketing strategies tailored to your business
              goals, boosting your brand's visibility, engaging your target
              audience, and driving growth.
            </p>
          </li>
          <li>
            <p className="mt-4 text-center">
              These are just a few examples of how we can help your business
              thrive. Let us empower your leadership team, enhance customer
              satisfaction, and mitigate risks for long-term resilience. With
              our guidance, your business will thrive in today's competitive
              landscape. Choose itechgram as your trusted partner in
              business consulting.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};
