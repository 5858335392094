import React from "react";
import { Link } from "react-router-dom";
import ItechLogo from "../assets/images/transparent-logo.png";
import { FaLocationDot } from "react-icons/fa6";
import { AiFillTikTok } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-[#1A1819] text-white pb-10">
      <div className="container mx-auto ">
        <div className="footer-section m-auto w-fit">
          <img
            src={ItechLogo}
            alt="Itechlogo"
            className=" w-[500px] max-sm:w-[300px] m-auto max-sm:ml-[-7px] cursor-pointer"
          />
        </div>
        <div
          className="mt-[-48px] flex
    justify-center cursor-pointer  hover:text-theme ml-8  max-sm:mt-[-25px]  max-sm:ml-0"
        >
          <FaLocationDot className="inline-block text-theme text-xl mr-2" />
          <div>
            <p className=" text-sm block">Mississauga, Ontario, Canada</p>
          </div>
        </div>
        {/* Contact Information Section */}
        <div className="footer-section text-center lg:text-left w-fit m-auto py-2 pb-4">
          <ul className="flex flex-row gap-14 text-sm mt-10 uppercase font-semibold">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>

            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
        </div>
        <hr></hr>

        <div className="flex flex-row max-md:flex-col max-md:gap-4 items-center max-md:justify-center max-lg:flex-col justify-between max-xl:px-6 max-md:px-0 m-auto py-8">
          <h2 className="text-base max-sm:text-sm">
            &copy; 2024 Right Reserved - Powered By Itechgram
          </h2>

          <div className="flex justify-center items-center lg:justify-start gap-2">
            <a
              target="_blank"
              href="https://www.instagram.com/itechgramsolutions"
            >
              {" "}
              <AiFillInstagram className="text-theme text-4xl" />{" "}
            </a>
            <a
              target="_blank"
              href="https://www.tiktok.com/@itechgram?_t=8meQIyxuNqX&_r=1"
            >
              <AiFillTikTok className="text-theme text-4xl" />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/in/itechgram-solutions-5ab94530b/"
            >
              <FaLinkedin className="text-theme text-[33px]" />
            </a>
          </div>
          <div>
            <p className="font-bold hover:text-theme text-base inline-block max-sm:text-xs mr-2">
              +1 (437) 2620900
            </p>
            <span>|</span>
            <a
              href="mailto:services.qcp@gmail.com"
              className="ml-2 inline-block text-base font-bold hover:text-theme  max-sm:text-xs"
            >
              services@itechgram.com
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
